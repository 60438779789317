import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { store } from '../../../../redux/store';
import isEmail from 'validator/lib/isEmail';
import * as actions from '../../../../redux/actions';

import { InputField } from '../../shared/Form';
import WeakPassword from '../../../../components/common/modals/WeakPassword';
import Button from '../../shared/Button';

import { useLoader, useNotification, useWindowSize } from '../../../hooks';
import i18n from '../../../../config/i18n';
import { loginCountry } from '../../../../config/config';
import { checkRecaptcha, handoverConstructor } from '../../../../components/utils/_app-helpers';
import { authLogin, sendAccessCodeEmail } from '../../../../components/utils/_auth-helpers';
// import {
//   addGTM,
//   addGTMLocalisationBE,
//   addGTMLocalisationNL,
// } from '../../../../components/utils/_gtm-helpers';
import { setRecaptcha, preventClipboardProps } from '../../../utils';
import { ImageProps } from '../../../../prepr/interfaces/Page';
import getLoginPageData from '../../../../prepr/queries/getLoginPageData';
import { errorsList } from '../../shared/Form/data';
import {
  LoginWrapper,
  LoginContentWrapper,
  LoginImageWrapper,
  LoginContentContainer,
  LoginTitle,
  FormWrapper,
  LoginInfoWrapper,
  LoginInfo,
  LoginLink,
  FieldWrapperLogin, FieldLink, FieldButton
} from './Login.styles';

type DataPageProps = {
  _id?: string;
  image_desktop?: ImageProps;
  image_mobile?: ImageProps;
}

type InputsType = {
  email: string;
  password: string;
};

function Login() {
  const { showNotification, notificationComponent } = useNotification({
    positionTop: '30px',
    width: 'auto'
  });
  const history = useHistory();
  const location = useLocation<{ isPasswordChanged: boolean, prevPath: string }>();
  const windowSize = useWindowSize();
  const shopForm = useRef<HTMLFormElement>(null);

  const [dataPage, setDataPage] = useState<DataPageProps>();
  const [isDataPageLoaded, setIsDataPageLoaded] = useState<boolean>(false);
  const [isEmailSend, setIsEmailSend] = useState<boolean>(false);
  const [isWeakPassword, setIsWeakPassword] = useState<boolean>(false);
  const [isReward, setIsReward] = useState<boolean>(false);
  const [isRedirectShop, setIsRedirectShop] = useState<boolean>(false);
  const [shopUrl, setShopUrl] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');

  const image = dataPage?.image_desktop;
  const imageUrl = image?.cdn_files?.[0]?.url || image?.url;
  const imageMob = dataPage?.image_mobile;
  const imageUrlMob = imageMob?.cdn_files?.[0]?.url || imageMob?.url;

  const isPasswordChanged = location?.state?.isPasswordChanged;

  const loader = useLoader(!isDataPageLoaded);

  const methods = useForm<InputsType>({ mode: 'all' });
  const {
    register,
    getValues,
    formState: { errors, isValid, isDirty }
  } = methods;

  const ShopRedirectForm = (props: { action: string, token: string }) => {
    return (
      <form id='handover-sso-form' action={props.action} method='POST' ref={shopForm}>
        <input type='hidden' name='auth_key' id='token-input' value={props.token} />
        <button style={{ display: 'none' }}></button>
      </form>
    );
  };

  const fetchLoginPageData = useCallback(async () => {
    setIsDataPageLoaded(false);

    try {
      const data = await getLoginPageData;

      if (data._id) {
        setDataPage(data);
      }
    } catch (e) {
      console.error('Login page data is not loaded');
    }

    setIsDataPageLoaded(true);
  }, []);

  const handleSendEmail = (token: string) => {
    setIsEmailSend(true);
    let email = getValues('email');
    sendAccessCodeEmail(email, token)
      .then((response) => {
        if (response.data.code === 4000) {
          history.push({
            pathname: '/auth/change-password',
            state: {
              isEmailSent: true
            }
          });
        }
      })
      .catch(error => {
        console.info(error);
        setIsEmailSend(false);
        if (error?.response?.data?.code === 40012) {
          showNotification(i18n.t('recaptcha_token_missing'), 'error');
        } else if (error?.response?.data?.code === 40013) {
          showNotification(i18n.t('recaptcha_invalid_token'), 'error');
        } else if (error?.response?.data?.code === 40014) {
          showNotification(i18n.t('recaptcha_score_issue'), 'error');
        } else if (error?.response?.data?.code === 409 || error?.response?.status === 409 || error?.response?.status === 429) {
          showNotification(i18n.t('too_many_requests_error'), 'error');
        } else {
          showNotification(i18n.t('05_Login_send-email_error'), 'error');
        }
      });
  };
  const forgotPasswordHandle = (e: any) => {
    e.preventDefault();

    let email = getValues('email');
    localStorage.setItem('userEmail', email);
    if (email.length !== 0) {
      checkRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY, handleSendEmail, showNotification)(e);
    } else {
      showNotification(i18n.t('05_Forgot_password_error'), 'error');
    }
  };

  const showSuccessPasswordChangedMessage = useCallback(() => {
    if (isPasswordChanged) {
      showNotification(i18n.t('05_Login_change-weak-password_password-changed'), 'success');
    }
  }, [isPasswordChanged, showNotification]);

  const handleCloseWeaKPasswordModal = () => {
    setIsWeakPassword(false);
  };

  const parseUrl = (getParam: string) => {
    const url = window.location.href;
    const get = url.split('?')[1];
    if (get) {
      const getArr = get.split('&');
      getArr.forEach((param) => {
        if (param.split('=')[0] === getParam) {
          setIsReward(true);
        }
      });
    }
  };

  const parseShopUrl = (getParam: string, accessToken: string) => {
    const url = window.location.href;
    const get = url.split('?')[1];
    if (get) {
      const getArr = get.split('&');
      getArr.forEach((param) => {
        const shopUrl = getArr[0]?.split('=')[1];
        if (param.split('=')[0] === getParam && Boolean(shopUrl)) {
          let shopURL = shopUrl[0] === '/' ? handoverConstructor(shopUrl.slice(1)) : handoverConstructor(shopUrl);
          setIsRedirectShop(true);
          setShopUrl(shopURL);
          setAccessToken(accessToken);
        }
      });
    }
  };

  const handleLogin = (token: string) => {
    let email = getValues('email');
    let password = getValues('password');

    if (!isEmail(email)) {
      return showNotification(i18n.t('08_invalid_email_format'), 'error');
    }

    const data = { email };
    localStorage.setItem('userEmail', email);
    store.dispatch(actions.addCustomerDetails(data));
    localStorage.removeItem('regWithCard');

    let settings = {
      'email': email,
      'password': password,
      'cardNumber': '', //cardNumber,
      'country': loginCountry,
      'token': token
    };

    authLogin(settings)
      .then(response => {
        if (response.data.code === 4000) {
          const dateNow = Date.now();
          const expiresIn = response.data?.payload?.expiresIn;
          const accessExpiresAt = (+expiresIn) * 1000 + dateNow;
          localStorage.setItem('accessExpiresAt', accessExpiresAt?.toString());
          localStorage.setItem('authToken', JSON.stringify(response.data.payload));
          // @ts-ignore
          localStorage.setItem('authorized', true);

          //If user are went from Reward Popup, we need to redirect him after login to Reward page
          parseUrl('reward');
          if (isReward) {
            window.location.href = '#/rewards/all';
          }

          //If user went from Shop product page, we need to redirect him after login to Shop product page
          parseShopUrl('url', response.data.payload.accessToken);
          if (isRedirectShop) {
            shopForm?.current?.submit();
            return false;
          }

          let prevPath = location?.state?.prevPath;
          if (prevPath) {
            window.location.href = `#${prevPath}`;
          }


          window.location.reload();
        }
      })
      .catch(error => {
        if (error?.response?.data?.code === 4017) {
          const password = getValues('password');
          localStorage.setItem('interimCode', password);
          window.location.href = '#/auth/temporary-password';
        } else if (error?.response?.data?.code === 409 || error?.response?.status === 409 || error?.response?.status === 429) {
          showNotification(i18n.t('too_many_requests_error'), 'error');
        } else if (error?.response?.data?.code === 4013) {
          window.location.href = '#/email-sent';
        } else if (error?.response?.data?.code === 4015) {
          showNotification(i18n.t('08_invalid_country_code'), 'error');
        } else if (error?.response?.data?.code === 4016) {
          showNotification(i18n.t('08_forgot_password_error'), 'error');
        } else if (error?.response?.data?.code === 4011) {
          showNotification(i18n.t('08_forbidden_response_error'), 'error');
        } else if ((error?.response?.data?.code === 406)) {
          setIsWeakPassword(true);
        } else {
          showNotification(i18n.t('cors_error'), 'error');
        }
      });
  };

  useEffect(() => {
    setRecaptcha();
    showSuccessPasswordChangedMessage();

    //Analytics for E-bike Campaign
    // if(shopLang === 'nl_nl'){
    //   addGTM('/count0/signup+standard', {}, 'DC-374307');
    //   addGTMLocalisationNL('signup', 'count0');
    // }else if (shopLang === 'be_nl'){
    //   addGTM('/essoe0/2023_00+unique', {}, 'DC-4212618');
    //   addGTMLocalisationBE('2023_00');
    // }else{
    //   addGTM('/essoe0/2023_0+unique', {}, 'DC-4212618');
    //   addGTMLocalisationBE('2023_0');
    // }
    //end Analytics for E-bike Campaign
  }, [showSuccessPasswordChangedMessage]);

  useEffect(() => {
    fetchLoginPageData();
  }, [fetchLoginPageData]);

  useEffect(() => {
    let authorized = localStorage.getItem('authorized');
    if (authorized) {
      history.push({ pathname: '/' });
    }
  }, [history]);

  return (
    loader ||
    <LoginWrapper>
      <LoginContentWrapper>
        <LoginContentContainer>
          <LoginTitle>{i18n.t('05_Login_title')}</LoginTitle>

          <FormProvider {...methods}>
            <FormWrapper onSubmit={checkRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY, handleLogin, showNotification)}>
              <FieldWrapperLogin>
                <FieldLink to={'/auth/forgotten-email'}>{i18n.t('05_Forgot_email_title')}</FieldLink>
                <InputField
                  register={{
                    ...register('email', {
                      required: errorsList.email.required,
                      pattern: {
                        value: /^(.)+$/,
                        message: errorsList.email.required
                      }
                    })
                  }}
                  type='email'
                  placeholder={i18n.t('05_Login_email_field')}
                  errorText={errors.email?.message}
                  name='email'
                  label={i18n.t('05_Login_email_field')}
                  width={100}
                  isError={!!errors.email}
                  isRequired
                  {...preventClipboardProps()}
                />
              </FieldWrapperLogin>

              <FieldWrapperLogin>
                <FieldButton onClick={forgotPasswordHandle}>{i18n.t('forgot_password')}</FieldButton>
                <InputField
                  register={{
                    ...register('password', {
                      required: errorsList.password.required,
                      pattern: {
                        value: /^(.)+$/,
                        message: errorsList.password.required
                      }
                    })
                  }}
                  type='password'
                  placeholder={i18n.t('05_Login_password')}
                  errorText={errors.password?.message}
                  name='password'
                  label={i18n.t('05_Login_password')}
                  width={100}
                  isError={!!errors.password}
                  isRequired
                  isEyeIconActive={true}
                  {...preventClipboardProps()}
                />

              </FieldWrapperLogin>
              <Button className={'btnLogin'}
                      disabled={!isValid || !isDirty}
                      text={i18n.t('05_Login_reg_continue_button')}
                      type='submit' />
            </FormWrapper>
          </FormProvider>

          <WeakPassword
            isModalOpen={isWeakPassword}
            email={getValues('email')}
            onCloseModal={handleCloseWeaKPasswordModal}
            onSendEmail={checkRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY, handleSendEmail, showNotification)}
            isButtonDisabled={isEmailSend}
          />
          {isRedirectShop && (<ShopRedirectForm action={shopUrl} token={accessToken} />)}

          <LoginInfoWrapper>
            <LoginInfo>{i18n.t('login_additional_info')}</LoginInfo>{' '}
            <LoginLink to={'/auth/registration/?regOption=3'}>{i18n.t('link_to_registration')}</LoginLink>
          </LoginInfoWrapper>
        </LoginContentContainer>
      </LoginContentWrapper>

      <LoginImageWrapper>
        {windowSize > 1024 && imageUrl && <img src={imageUrl} alt='banner' />}
        {windowSize <= 1024 && imageUrlMob && <img src={imageUrlMob} alt='banner' />}
      </LoginImageWrapper>

      {notificationComponent}
    </LoginWrapper>
  );
}

export default Login;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkLoggedIn } from "../../../../components/utils/_app-helpers";
import { useHistory } from "react-router-dom";
import { addGTM, addGTMLocalisationNL } from "../../../../components/utils/_gtm-helpers";

interface PageStubProps {
  redirectURL?: string;
  hardReload?: boolean;
}

function PageStub({ redirectURL, hardReload }: PageStubProps) {
  const history = useHistory();
  const [url, setUrl] = useState<null | string>(null);
  const isLoggedIn = checkLoggedIn();
  const isCurrentUserActive = useSelector((state: any) => state?.isCurrentUserActive);
  const dataLoaded = useSelector((state: any) => state?.dataLoaded?.success);

  useEffect(() => {

    ////Analytics for E-bike Campaign for NL
    addGTM('/count0/landextr+standard', {}, 'DC-374307');
    addGTMLocalisationNL('landextr', 'count0');
    //end Analytics for E-bike Campaign

  }, []);

  useEffect(() => {
    if(redirectURL){
      setUrl(redirectURL);
    }else{

      if(isLoggedIn){
        if(dataLoaded){
          if(isCurrentUserActive){
            setUrl('homepage-member-active');
          }else{
            setUrl('homepage-member-inactive');
          }
        }
      }else{
        setUrl('home-page');
      }
    }
  },[redirectURL, isLoggedIn, dataLoaded, isCurrentUserActive, setUrl])

  useEffect(() => {
    if(url){
      const hashURLEntity = window.location.hash.split('/')[1];

      if (hashURLEntity === '' || hardReload) {
        history.push(`/${url}`);
      }
    }
  }, [hardReload, url, history]);
}

export default PageStub;